
.fade-enter {
    opacity: 0;
    transform: translate(0, 0px);
    z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;
    transform: translate(0, 0);

    transition: opacity 650ms ease-out;
}
.fade-exit {
    opacity: 1;
    transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transform: translate(0, 0px);

    transition: opacity 650ms;
}

