@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300&family=Oranienbaum&family=Oswald:wght@200;400;500&display=swap');
/*Print styles for single property page*/
.page {
  width: 98%;
  margin: 0 auto;
  grid-area: main;
}

.print-page-btn {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.print-logo {
  display: none;
}

#my-gallery {
  padding-top: -40px;
}

@media print {
    h1 {break-before: page}
  .page {
    margin: 10px;
  }
  .print-logo {
    display: flex;
    width: 98%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .print-page-break {
      position:absolute;
      top: 100%;
      margin-top: 10rem;
      float: none !important;
      display: inline-block;
    page-break-inside: avoid !important;
    page-break-before: page !important;
  }
  .hidden-on-print {
    display: none;
  }

  #my-gallery {
    margin-top: 1rem;
    width: 200mm;
    height: 500px;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
  }
}
.headings {
  color: #62707e;
}
h1 {
  color: #62707e;
  font-family: 'Oranienbaum', serif;
  font-size: 1.8rem;
  line-height: 2.5rem;
}

h2 {
  color: #62707e;
  font-family: 'Oranienbaum', serif;
  font-size: 1.6rem;
  line-height: 2rem;
}

h3 {
  color: #62707e;
  font-family: 'Oranienbaum', serif;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

h4 {
  color: #62707e;
  font-family: 'Oranienbaum', serif;
  font-size: 1.2rem;
  line-height: 1rem;
}
p {
  min-height: 1rem;
  margin-top: 1rem;
  font-weight: 300;
  color: #62707e;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.1em;
}

ul {
  min-height: 1rem;
  margin-top: 1rem;
  font-weight: 300;
  color: #62707e;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.1em;
}

li {
    margin-bottom: 1rem;
}

.icon {
  margin-right: 0.5rem;
}
.subMenu {
  color: #1d425d !important;
}
.home-component {
  background-color: #1d425d;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  text-align: center;
}

.main-page-content {
  margin-bottom: 7rem;
  margin-top: 1rem;
  grid-area: main;
  padding: 2rem;
    padding-top: 5rem;
  text-align: left;
  background-color: transparent;
}

.three-column-page {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  grid-area: main;
  padding: 2rem;
  text-align: left;
}
.three-column-page-text {
  width: 100%;
}

.three-column-page-image-container {
  width: 100%;
  top: -15%;
  margin-right: 10%;
  height: 100%;
}
.inputs-divs {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.form-input:focus {
  outline: none;
  border: 2px solid #d0c6b7;
  border-radius: 6px;
  background-color: #f3f3f1;
}

.form-input {
  width: 15rem;
  margin: 0;
  border: 1px solid #d0c6b7;
  height: 1.5rem;
  border-radius: 6px;
}
.text-area {
  font-weight: 300;
  color: #62707e;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.1em;
  margin: 10px 0 10px 0;
  border-radius: 6px;
  border: 1px solid #d0c6b7;
}

.text-area:focus,
.text-area:active {
  outline: none;
  background-color: #f3f3f1;
  border: 1px solid #d0c6b7;
}

.form-label {
  min-width: 130px;
  font-weight: 300;
  color: #62707e;
  font-size: 1rem;
  font-family: 'Lato', sans-serif;
  letter-spacing: 0.1em;
}
/*///////////////////*/
@media screen and (min-width: 850px) {
  .page {
    margin-top: 4rem;
  }

  .main-page-content {
    margin-top: 10rem;
    grid-area: main;
    padding-right: 4rem;
  }

  .three-column-page {
    justify-content: space-around;
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    padding: 0 0.2rem 0 2.5rem;
    text-align: left;
  }

  .three-column-page-text {
    width: 80%;
  }

  .three-column-page-image-container {
    top: -15%;
    margin-right: 5%;
    contain: content;
    height: 100%;
    width: 80%;
  }
  .home-component {
    background-color: #1d425d;
    grid-row: 1 / -1;
    grid-column: 1 / -1;
    text-align: center;
  }
}

@media screen and (min-width: 1400px) {
  .three-column-page {
    flex-direction: row;
    text-align: left;
  }

  .three-column-page-text {
    width: 90%;
    margin-right: 10%;
  }

  .three-column-page-image-container {
    top: -15%;
    margin-right: -20%;
    contain: content;
    height: 100%;
    width: 80%;
  }
}
@media screen and (min-width: 1500px) {
  .main-page-content {
    margin-top: 10rem;
    grid-area: main;
  }
  .three-column-page {
    justify-content: space-between;
    margin-top: 10rem;
    display: flex;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    grid-column-end: 4;
    flex-direction: row;
    padding: 1rem 0;
    text-align: left;
  }

  .three-column-page-text {
    width: 50%;
  }

  .three-column-page-image-container {
    top: -15%;
    margin-right: 10%;
    contain: content;
    height: 100%;
    width: 30%;
  }
  .home-component {
    background-color: #1d425d;
    grid-row: 1 / -1;

    grid-column: 1 / -1;
    text-align: center;
  }
}
